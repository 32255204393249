var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',{attrs:{"title":`📷 Imagenes por tipología ${_vm.prjUnitType.type}.`,"title-color":"black"}},[_c('p',{staticClass:"mb-1"},[_vm._v("\n      Agrega la imagen que deseas para las tipologias.\n    ")])]),_c('div',{staticClass:"flex flex-end"},[_c('Popup',{attrs:{"title":` `,"showPopup":_vm.imagePopUp},on:{"close":_vm.closePopUp}},[_c('ImageUpload',{attrs:{"slot":"content","resize":{
          width: 900,
          height: 506,
          quality: 60
        },"crop":{
          ratioX: 16,
          ratioY: 9
        },"dir":_vm.logoDir},on:{"cancel-upload":_vm.cancelUpload,"upload":_vm.uploadImage},slot:"content"})],1),_c('vs-button',{staticClass:"mt-4",attrs:{"color":"success","icon":"feather","icon-pack":"icon-plus"},on:{"click":function($event){_vm.imagePopUp = true}}},[_vm._v("Agregar planos "+_vm._s(_vm.typeSelected.label))])],1),_c('vs-row',[_c('vs-col',{staticClass:"mt-8",attrs:{"vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('ag-grid-vue',{staticClass:"ag-theme-material ag-grid-table h-screen w-100",attrs:{"defaultColDef":_vm.defaultColDef,"columnDefs":_vm.columnDefs,"rowData":_vm.prjUnitTypeImages,"pagination":true,"paginationPageSize":_vm.itemsPerPage,"floatingFilter":true,"frameworkComponents":_vm.frameworkComponents,"rowHeight":150}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }