<template>
  <div>
    <vx-card
      :title="`📷 Imagenes por tipología ${prjUnitType.type}.`"
      title-color="black"
    >
      <p class="mb-1">
        Agrega la imagen que deseas para las tipologias.
      </p>
    </vx-card>
    <div class="flex flex-end">
      <Popup
        :title="` `"
        :showPopup="imagePopUp"
        @close="closePopUp"
      >
        <ImageUpload
          :resize="{
            width: 900,
            height: 506,
            quality: 60
          }"
          :crop="{
            ratioX: 16,
            ratioY: 9
          }"
          :dir="logoDir"
          @cancel-upload="cancelUpload"
          @upload="uploadImage"
          slot="content"
        />
      </Popup>
      <vs-button
        class="mt-4"
        color="success"
        icon="feather"
        icon-pack="icon-plus"
        @click="imagePopUp = true"
        >Agregar planos {{ typeSelected.label }}</vs-button
      >
    </div>
    <vs-row>
      <vs-col class="mt-8" vs-justify="center" vs-align="center" vs-w="12">
        <ag-grid-vue
          class="ag-theme-material ag-grid-table h-screen w-100"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="prjUnitTypeImages"
          :pagination="true"
          :paginationPageSize="itemsPerPage"
          :floatingFilter="true"
          :frameworkComponents="frameworkComponents"
          :rowHeight="150"
        ></ag-grid-vue>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { api, unitTypeApi } from "../../projects/services";
import slugify from "../../../helpers/slugify";
import { AgGridVue } from "ag-grid-vue";
import AgImage from "../../../components/AgGridComponents/AgImage.vue";
import AgGroupButton from "../../../components/AgGridComponents/AgGroupButton.vue";
import Popup from "../../../components/Popup.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  data() {
    return {
      typeSelected: "",
      itemsPerPage: 5,
      project: null,
      imagePopUp: false,
      prjUnitType: null,
      prjUnitTypeImages: [],
      defaultColDef: {
        filter: true,
        sortable: true,
        filterParams: {
          alwaysShowBothConditions: true,
          defaultJoinOperator: "OR"
        }
      },
      columnDefs: null
    };
  },
  components: {
    ImageUpload,
    Popup,
    AgGridVue
  },
  beforeMount() {
    this.frameworkComponents = {
      agImage: AgImage,
      agGroupButton: AgGroupButton
    };
  },
  async mounted() {
    await this.getPrjUnitTypeImages();

    this.columnDefs = [
      {
        headerName: "Plano",
        field: "image_url",
        sortable: false,
        filter: false,
        cellRenderer: "agImage",
        cellRendererParams: params => {
          return {
            src: params.data.image_url
          };
        }
      },
      {
        headerName: "Acciones",
        field: "id",
        filter: false,
        sortable: false,
        width: 500,
        cellRenderer: "agGroupButton",
        cellRendererParams: () => {
          return {
            buttons: [
              {
                color: "danger",
                icon: "icon-trash",
                event: "delete-event"
              }
            ]
          };
        }
      }
    ];

    this.onAgGroupEventButton();
  },
  computed: {
    logoDir() {
      const developerName = slugify(this.project.property_developer.name);
      const projectName = slugify(this.project.name);

      return `${developerName}/projects/${projectName}/gallery`;
    }
  },
  methods: {
    async getPrjUnitTypeImages() {
      try {
        const projectId = this.$store.state.project.currentProjectId;
        const { projectUnitTypeId } = this.$route.params;

        this.project = await api.get(projectId);

        this.prjUnitType = await unitTypeApi.getByPk(projectUnitTypeId);
        this.prjUnitTypeImages = this.prjUnitType.project_unit_type_images;
      } catch (e) {
        sentryCaptureException(e, "project-unit-type");
      }
    },
    closePopUp() {
      this.imagePopUp = false;
    },
    cancelUpload() {
      this.imagePopUp = false;
    },
    async uploadImage(images) {
      try {
        const prjUnitTypeImages = images.map(i => {
          return {
            project_unit_type_id: this.prjUnitType.id,
            image_url: i.location
          };
        });

        await unitTypeApi.addImages(prjUnitTypeImages);

        this.imagePopUp = false;

        await this.getPrjUnitTypeImages();

        this.$vs.notify({
          title: `Imagen tipo ${this.prjUnitType.type}.`,
          text: `Imagen almacenada correctamente.`,
          color: "success"
        });
      } catch (e) {
        sentryCaptureException(e, "project-unit-type");
        this.$vs.notify({
          title: `Error al cargar planos`,
          text: `No ha sido posible guardar plano en este momento.`,
          color: "danger"
        });
      }
    },
    onAgGroupEventButton() {
      this.$root.$on("delete-event", async data => {
        try {
          await unitTypeApi.deleteImageByPk(data.id);
          await this.getPrjUnitTypeImages();

          this.$vs.notify({
            title: `Imagen tipo ${this.prjUnitType.type}.`,
            text: `Imagen eliminada correctamente.`,
            color: "success"
          });
        } catch (e) {
          sentryCaptureException(e, "project-unit-type");
          this.$vs.notify({
            title: `Error al eliminar planos`,
            text: `No ha sido posible eliminar plano en este momento.`,
            color: "danger"
          });
        }
      });
    }
  }
};
</script>
