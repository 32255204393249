<template>
    <div class="flex">
        <div v-for="(item, key) in params.buttons" :key="key">
            <vs-button
                :color="item.color"
                type="border"
                icon-pack="feather"
                :icon="item.icon"
                class="mr-2 mt-2"
                @click="onButtonEvent(item.event)"
            >
            </vs-button> 
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    methods: {
        onButtonEvent(event) {
            this.$root.$emit(event, this.params.data);
        }
    }
});
</script>